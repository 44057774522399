<template>
  <div class="modal fade" id="cardPaymentModel" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Card Payment</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="cardFailed == true" class="alert alert-danger" role="alert">
            {{ cardMsg }}
          </div>
          <div v-if="cardFailed == false" class="alert alert-danger" role="alert">
            {{ cardMsg }}
          </div>
          <form @submit.prevent="handleSubmit">
            <!-- Card Number -->
            <div class="mb-3">
              <label for="cardNumber" class="form-label">Card Number*</label>
              <input type="text" id="cardNumber" v-model="payment.CardNumber" @input="maskCardNumber"
                @keypress="allowNumbersOnly($event)" :class="inputClass('CardNumber')" class="form-control"
                placeholder="0000 0000 0000 0000" maxlength="23" />
              <small class="text-danger" v-if="errors.CardNumber">{{
                errors.CardNumber
              }}</small>
            </div>

            <!-- Expiration Date -->
            <div class="mb-3">
              <label for="date" class="form-label">Expiration Date (MM/YY)*</label>
              <input type="text" id="date" v-model="payment.Date" @input="autoFormatDate" :class="inputClass('Date')"
                class="form-control" placeholder="MM/YY" maxlength="5" />
              <small class="text-danger" v-if="errors.Date">{{
                errors.Date
              }}</small>
            </div>

            <!-- CVV -->
            <div class="mb-3">
              <label for="cvv" class="form-label">CVV*</label>
              <input type="text" id="cvv" v-model="payment.Cvv" @input="restrictInput('Cvv', 4)"
                @keypress="allowNumbersOnly($event)" @keyup="inputClass('Cvv')" :class="inputClass('Cvv')"
                class="form-control" placeholder="123" maxlength="4" />
              <small class="text-danger" v-if="errors.Cvv">{{
                errors.Cvv
              }}</small>
            </div>

            <!-- First Name -->
            <div class="mb-3">
              <label for="firstName" class="form-label">First Name*</label>
              <input type="text" id="firstName" v-model="payment.FirstName" @input="validateField('FirstName')"
                :class="inputClass('FirstName')" class="form-control" />
              <small class="text-danger" v-if="errors.FirstName">{{
                errors.FirstName
              }}</small>
            </div>

            <!-- Last Name -->
            <div class="mb-3">
              <label for="lastName" class="form-label">Last Name*</label>
              <input type="text" id="lastName" v-model="payment.LastName" @input="validateField('LastName')"
                :class="inputClass('LastName')" class="form-control" />
              <small class="text-danger" v-if="errors.LastName">{{
                errors.LastName
              }}</small>
            </div>

            <!-- Address Line 1 -->
            <div class="mb-3">
              <label for="addressLine1" class="form-label">Address Line 1*</label>
              <input type="text" id="addressLine1" v-model="payment.AddressLine1" @input="validateField('AddressLine1')"
                :class="inputClass('AddressLine1')" class="form-control" />
              <small class="text-danger" v-if="errors.AddressLine1">{{
                errors.AddressLine1
              }}</small>
            </div>

            <!-- Address Line 2 -->
            <div class="mb-3">
              <label for="addressLine2" class="form-label">Address Line 2</label>
              <input type="text" id="addressLine2" v-model="payment.AddressLine2" :class="inputClass('AddressLine2')"
                class="form-control" />
              <small class="text-danger" v-if="errors.AddressLine2">{{
                errors.AddressLine2
              }}</small>
            </div>

            <!-- City -->
            <div class="mb-3">
              <label for="city" class="form-label">City*</label>
              <input type="text" id="city" v-model="payment.City" @input="validateField('City')"
                :class="inputClass('City')" class="form-control" />
              <small class="text-danger" v-if="errors.City">{{
                errors.City
              }}</small>
            </div>

            <!-- State -->
            <div class="mb-3">
              <label for="state" class="form-label">State*</label>
              <select id="state" class="form-select" :class="inputClass('StateId')" v-model="payment.StateId"
                aria-label="Select a state" @change="validateField('StateId')">
                <option :value="null" selected>Select a state</option>
                <option v-for="state in states" :key="state.id" :value="state.id">
                  {{ state.name }}
                </option>
              </select>
              <small class="text-danger" v-if="errors.StateId">{{
                errors.StateId
              }}</small>
            </div>

            <!-- Zip Code -->
            <div class="mb-3">
              <label for="zipCode" class="form-label">Zip Code*</label>
              <input type="text" id="zipCode" v-model="payment.ZipCode" @input="restrictInput('ZipCode', 5)"
                @keypress="allowNumbersOnly($event)" :class="inputClass('ZipCode')" class="form-control"
                placeholder="12345" maxlength="5" />
              <small class="text-danger" v-if="errors.ZipCode">{{
                errors.ZipCode
              }}</small>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-style-two block" :disabled="loading" @click="handleSubmit">
            {{ loading ? "Processing..." : "Pay " + (btnText ? "" : "Now") }}
            <span v-if="btnText">{{
              btnText ? FormatCurrency(btnText.toString()) : ""
            }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EncryptionHandler from "./EncryptionHandler.js";
import { usePropertyStore } from "../../stores/properties";
import FormatCurrency from "../../utils/Currency.js";

export default {
  data() {
    return {
      selectedItem: null,
      searchTerm: "",
      states: [],
      payment: {
        CardNumber: "",
        Date: "",
        Cvv: "",
        FirstName: "",
        LastName: "",
        AddressLine1: "",
        AddressLine2: "",
        City: "",
        StateId: null,
        ZipCode: "",
      },
      errors: {},
      formSubmitted: false,
    };
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    btnText: {
      type: String,
      required: false,
      default: "",
    },
    cardFailed: {
      type: Boolean,
      required: false,
      default: null,
    },
    cardMsg: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["pay"],
  computed: {
    formValid() {
      // Check all required fields for validity
      const requiredFields = [
        "CardNumber",
        "Date",
        "Cvv",
        "FirstName",
        "LastName",
        "AddressLine1",
        "City",
        "StateId",
        "ZipCode",
      ];

      // Ensure all required fields have valid values and no errors
      return requiredFields.every(
        (field) =>
          !this.errors[field] &&
          this.payment[field] &&
          this.payment[field].trim().length > 0
      );
    },
    filteredItems() {
      if (!this.searchTerm) return this.states;
      return this.states.filter((item) =>
        item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
  },
  watch: {
    cardFailed(newValue) {
      if (newValue !== null) {
        // Scroll to top
        const modalBody = document.querySelector(
          "#cardPaymentModel .modal-body"
        );
        if (modalBody) {
          modalBody.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      }
    },
  },
  methods: {
    FormatCurrency,
    validateField(field) {
      const rawValue = this.payment[field];
      let value = null;

      if (field == "StateId") {
        // Handle StateId specifically
        console.log("StateId", rawValue);
        const isValidStateId =
          rawValue !== null && /^[0-9]+$/.test(String(rawValue));
        console.log("StateIdValidity", isValidStateId);
        this.errors[field] = isValidStateId ? "" : "Select a valid state.";
        return;
      }

      // Handle string and generic fields
      value = rawValue != null ? rawValue.trim() : rawValue || "";

      switch (field) {
        case "CardNumber":
          this.errors[field] = new RegExp(
            '^' +
            '(?:' +
            '(?:\\d{4}\\s?\\d{4}\\s?\\d{4}\\s?\\d{4})' +
            '|' +
            '(?:3[47]\\d{2}\\s?\\d{6}\\s?\\d{5})' +
            ')' +
            '$'
          ).test(value)
            ? ""
            : "Invalid card number format.";
          break;
        case "Date":
          this.errors[field] = /^(0[1-9]|1[0-2])\/\d{2}$/.test(value)
            ? ""
            : "Date must be in MM/YY format.";
          break;
        case "Cvv":
          this.errors[field] = /^[0-9]{3,4}$/.test(value)
            ? ""
            : "CVV must be 3 or 4 digits.";
          break;
        case "ZipCode":
          this.errors[field] = /^[0-9]{5}$/.test(value)
            ? ""
            : "Zip code must be 5 digits.";
          break;
        case "FirstName":
          this.errors[field] = /^[a-zA-Z\s'-]+$/.test(value)
            ? ""
            : "First name can only contain letters, spaces, or hyphens.";
          break;
        case "LastName":
          this.errors[field] = /^[a-zA-Z\s'-]+$/.test(value)
            ? ""
            : "Last name can only contain letters, spaces, or hyphens.";
          break;
        case "AddressLine1":
          this.errors[field] = value ? "" : "Address Line 1 cannot be empty.";
          break;
        case "City":
          this.errors[field] = /^[a-zA-Z\s'-]+$/.test(value)
            ? ""
            : "City can only contain letters, spaces, or hyphens.";
          break;
        case "StateId":
          this.errors[field] = /^[0-9]+$/.test(value)
            ? ""
            : "Select a valid state.";
          break;
        default:
          break;
      }
    },
    inputClass(field) {
      if (this.formSubmitted) {
        if (this.errors[field] && this.formSubmitted) {
          return "is-invalid";
        }
        if (field === "StateId" && this.payment[field] !== null) {
          // Handle StateId specifically for numeric validation
          return "is-valid";
        }
        if (this.payment[field] && this.payment[field].trim().length > 0) {
          return "is-valid";
        }
      }
      return "";
    },

    allowNumbersOnly(event) {
      const charCode = event.key;
      const validChars = "0123456789";
      if (!validChars.includes(charCode)) {
        event.preventDefault();
      }
    },
    maskCardNumber() {
      let value = this.payment.CardNumber.replace(/\D/g, "");

      const isAmex = /^3[47]/.test(value);

      if (isAmex) {
        value = value.slice(0, 15);

        const match = value.match(/^(\d{1,4})(\d{1,6})?(\d{1,5})?$/);

        if (match) {
          value = [match[1], match[2], match[3]].filter(Boolean).join(" ");
        }
      } else {
        value = value.slice(0, 16);

        const groups = value.match(/.{1,4}/g);
        value = groups ? groups.join(" ") : "";
      }

      this.payment.CardNumber = value;
      this.validateField("CardNumber");
    },
    autoFormatDate() {
      let value = this.payment.Date.replace(/\D/g, ""); // Remove non-digit characters
      if (value.length > 2) {
        value = `${value.slice(0, 2)}/${value.slice(2)}`; // Insert `/` after the first two digits
      }
      this.payment.Date = value.slice(0, 5); // Restrict to "MM/YY"
      this.validateField("Date");
    },
    restrictInput(field, maxLength) {
      const value = this.payment[field];
      if (value.length > maxLength) {
        this.payment[field] = value.slice(0, maxLength); // Truncate to max length
      }
      this.validateField(field);
    },
    handleSubmit() {
      this.formSubmitted = true;

      // Validate all required fields
      const requiredFields = [
        "CardNumber",
        "Date",
        "Cvv",
        "FirstName",
        "LastName",
        "AddressLine1",
        "City",
        "StateId",
        "ZipCode",
      ];

      requiredFields.forEach((field) => this.validateField(field));

      // Check if there are validation errors
      const hasErrors = Object.values(this.errors).some((error) => error);

      if (hasErrors) {
        document.querySelector("#cardPaymentModel .modal-body").scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }

      if (!hasErrors) {
        // Prepare data for submission
        const paymentData = {
          ...this.payment,
          CardNumber: this.payment.CardNumber.replace(/\s/g, ""), // Remove spaces from the card number
        };

        // Encrypt and emit the data
        const encryptedPaymentData = EncryptionHandler(
          JSON.stringify(paymentData)
        );
        this.$emit("pay", encryptedPaymentData);
      }
    },
    selectItem(item) {
      this.selectedItem = item.name;
      this.searchTerm = "";
    },
  },
  beforeMount() {
    const propertyStore = usePropertyStore();

    propertyStore.getAllStates().then((res) => {
      if (res.status == 200) {
        this.states = res.data;
      }
    });
  },
};
</script>

<style>
.is-valid {
  border-color: #198754;
}

.is-invalid {
  border-color: #dc3545;
}
</style>
