import JSEncrypt from "jsencrypt";

const EncryptionHandler = (data) => {
  const publicKey = process.env.VUE_APP_PUBLIC_ENCR_KEY;

  if (!publicKey) {
    throw new Error("Public key is not defined");
  }

  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  const encrypted = encrypt.encrypt(data);

  if (!encrypted) {
    throw new Error("Encryption failed");
  }

  return encrypted; // This is already base64-encoded
};

export default EncryptionHandler;
