<template>
  <div class="step-form">
    <b-container>
      <div class="section-title">
        <span class="sub-title purple-color">List Property</span>
        <h2 class="nunito-font">Step {{ currentStep }}</h2>
      </div>
      <div v-if="showSuccess" class="alert alert-success" role="alert">
        Listing Successfully submitted!
      </div>
      <b-row class="justify-content-center">
        <div class="col-lg-6 col-md-12">
          <div class="goal-content purple-color">
            <span class="sub-title">How hard is it to list my property?</span>
            <h2 class="nunito-font">Hassle Free Listing</h2>
            <ul class="overview-list">
              <li data-aos="fade-up" data-aos-duration="1200">
                <i class="flaticon-draw-check-mark"></i>
                We will need your address and your own photos.
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <i class="flaticon-draw-check-mark"></i>
                Any extra documentation like surveys.
              </li>
              <li
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <i class="flaticon-draw-check-mark"></i>
                Pick your open house date for agents.
              </li>
            </ul>
            <div
              class="btn-box"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="300"
            >
              <router-link
                to="/AddPropertyListing"
                class="btn-style-one purple-color"
              >
                Let's get started
                <i class="ph-caret-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        <b-col cols="12" md="6">
          <b-card class="custom-card">
            <b-card-body>
              <div class="intro-text">
                <h2 class="green-color2">Let's get started.</h2>
              </div>

              <div v-if="currentStep === 1">
                <div class="intro-text">
                  <h3 class="orangeColor">Add your property address.</h3>
                </div>
                <b-form @submit.prevent="validateStep(1)">
                  <b-form-group>
                    <b-form-input
                      v-model="formData.address1"
                      placeholder="Address 1"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <b-form-input
                      v-model="formData.address2"
                      placeholder="Address 2 (Optional)"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <b-form-input
                      v-model="formData.city"
                      placeholder="City"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <b-form-select
                      v-model="formData.state"
                      :options="states"
                      required
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group>
                    <b-form-input
                      v-model="formData.zipCode"
                      placeholder="Zip Code"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-button
                    type="submit"
                    class="btn-next btn-style-one green-color2"
                    >Next Step →</b-button
                  >
                </b-form>
              </div>

              <div v-else-if="currentStep === 2">
                <div class="intro-text">
                  <h3 class="orangeColor">Listing Agreement Terms</h3>
                </div>
                <b-form @submit.prevent="validateStep(2)">
                  <b-form-group>
                    <input
                      class="form-control"
                      v-model="formattedValue"
                      @blur="_formatCurrency"
                      placeholder="Bottom Line Price"
                      required
                    />
                  </b-form-group>
                  <b-form-group>
                    <b-form-select
                      v-model="formData.lengthOfTerm"
                      :options="termLengths"
                      required
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group>
                    <b-form-select
                      v-model="formData.commissionOffered"
                      :options="commissions"
                      required
                    ></b-form-select>
                  </b-form-group>
                  <b-button
                    type="button"
                    class="btn-prev btn-style-one orange-color"
                    @click="previousStep"
                    >← Back</b-button
                  >
                  <b-button
                    type="submit"
                    class="btn-next btn-style-one green-color2"
                    >Next Step →</b-button
                  >
                </b-form>
              </div>

              <div v-else-if="currentStep === 3">
                <div class="intro-text">
                  <h3 class="orangeColor">Select a date for open house</h3>
                </div>
                <b-form @submit.prevent="validateStep(3)">
                  <b-form-group>
                    <input
                      type="date"
                      v-model="formData.openHouseStart"
                      placeholder="Open House and Auction Start Date"
                      class="form-control"
                      required
                    />
                  </b-form-group>
                  <b-form-group>
                    <input
                      type="time"
                      v-model="formData.openHouseStartTime"
                      placeholder="Start Time"
                      class="form-control"
                      required
                    />
                  </b-form-group>
                  <b-button
                    type="button"
                    variant="secondary"
                    class="btn-prev btn-style-one orange-color"
                    @click="previousStep"
                    >← Back</b-button
                  >
                  <b-button
                    type="submit"
                    class="btn-next btn-style-one green-color2"
                    >Next Step →</b-button
                  >
                </b-form>
              </div>

              <div v-else-if="currentStep === 4">
                <div class="intro-text">
                  <h3 class="orangeColor">Add Photos of Your Property.</h3>
                </div>
                <b-form @submit.prevent="validateStep(4)">
                  <b-form-group>
                    <div class="custom-file drop-area">
                      <input
                        type="file"
                        class="custom-file-input"
                        id="customFile"
                        @change="handleFileUpload"
                        multiple
                      />
                      <label class="custom-file-label" for="customFile"
                        >Click to Select Files</label
                      >
                    </div>
                  </b-form-group>
                  <div
                    v-if="formData.propertyPhotos.length > 0"
                    class="file-list"
                  >
                    <ul>
                      <li
                        v-for="(file, index) in formData.propertyPhotos"
                        :key="index"
                      >
                        {{ file.name }}
                        <b-button
                          @click="removeFile(index, 'propertyPhotos')"
                          variant="danger"
                          size="sm"
                          >Remove</b-button
                        >
                      </li>
                    </ul>
                  </div>
                  <b-button
                    type="button"
                    variant="secondary"
                    class="btn-prev btn-style-one orange-color"
                    @click="previousStep"
                    >← Back</b-button
                  >
                  <b-button
                    type="submit"
                    variant="primary"
                    class="btn-next btn-style-one green-color2"
                    >Next Step →</b-button
                  >
                </b-form>
              </div>

              <div v-else-if="currentStep === 5">
                <div class="intro-text">
                  <h3 class="orangeColor">Additional Documents like surveys</h3>
                </div>
                <b-form @submit.prevent="validateStep(5)">
                  <b-form-group>
                    <div class="custom-file drop-area">
                      <input
                        type="file"
                        class="custom-file-input"
                        id="customFile"
                        @change="handleFileUpload"
                        multiple
                      />
                      <label class="custom-file-label" for="customFile"
                        >Click to Select Files</label
                      >
                    </div>
                  </b-form-group>
                  <div
                    v-if="formData.additionalDocuments.length > 0"
                    class="file-list"
                  >
                    <ul>
                      <li
                        v-for="(file, index) in formData.additionalDocuments"
                        :key="index"
                      >
                        {{ file.name }}
                        <b-button
                          @click="removeFile(index, 'additionalDocuments')"
                          variant="danger"
                          size="sm"
                          >Remove</b-button
                        >
                      </li>
                    </ul>
                  </div>
                  <b-button
                    type="button"
                    variant="secondary"
                    class="btn-prev btn-style-one orange-color"
                    @click="previousStep"
                    >← Back</b-button
                  >
                  <b-button
                    type="submit"
                    variant="primary"
                    class="btn-next btn-style-one green-color2"
                    >Next Step →</b-button
                  >
                </b-form>
              </div>

              <div v-else-if="currentStep === 6">
                <div class="intro-text">
                  <h3 class="orangeColor">Payment</h3>
                </div>
                <b-form @submit.prevent="submitForm">
                  <b-form-group>
                    <!-- Add your payment input fields here -->
                    <!-- <b-form-input v-model="formData.cardNumber" placeholder="Card Number" required></b-form-input> -->
                    <!-- <div ref="cardElementContainer" class="card-element mt-4 mb-4"></div> -->
                  </b-form-group>
                  <b-button
                    type="button"
                    variant="secondary"
                    class="btn-prev btn-style-one orange-color"
                    @click="previousStep"
                    >← Back</b-button
                  >
                  <b-button
                    type="submit"
                    variant="#C72FAA"
                    class="btn-next btn-style-one green-color2"
                    >Check Out</b-button
                  >
                </b-form>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <button
      id="confirmationModalBtn"
      type="button"
      class="btn btn-primary d-none"
      data-bs-toggle="modal"
      data-bs-target="#confirmationModal"
    ></button>
    <button
      id="confirmationModalCloseBtn"
      type="button"
      class="btn btn-primary d-none"
      data-bs-dismiss="modal"
      data-bs-target="#confirmationModal"
    ></button>
    <div
      class="modal fade"
      id="confirmationModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="confirmationModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="confirmationModalLabel">
              Membership Confirmation
            </h4>
          </div>
          <form @submit.prevent="handleSubmit">
            <div class="modal-body fs-5">
              <div>
                <div
                  ref="cardElementContainer"
                  class="card-element mt-4 mb-4"
                ></div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary fs-5"
                data-bs-dismiss="modal"
                :disabled="loader"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary fs-5"
                :disabled="loader"
                @click="payProperty"
              >
                {{ getPayButtonText() }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <CardPayment @pay="payProperty" :loading="cardProcessing"></CardPayment>
  </div>
</template>

<script>
import { usePropertyStore } from "../../stores/properties";
import FormatCurrency from "../../utils/Currency";
import { DateToUTC } from "../../utils/DateConverter";
import { loadStripe } from "@stripe/stripe-js";
import { usePaymentStore } from "../../stores/payments";
import CardPayment from "../Payment/CardPayment.vue";

export default {
  components: {
    CardPayment,
  },
  setup() {
    const propertyStore = usePropertyStore();
    const paymentStore = usePaymentStore();

    return {
      propertyStore,
      paymentStore,
    };
  },
  data() {
    return {
      currentStep: 1,
      formData: {
        address1: "",
        address2: "",
        city: "",
        state: null,
        zipCode: "",
        bottomLinePrice: "",
        lengthOfTerm: null,
        commissionOffered: null,
        openHouseStart: null,
        openHouseStartTime: null,
        propertyPhotos: [],
        additionalDocuments: [],
        cardNumber: "",
      },
      formattedValue: "",
      showSuccess: false,
      states: [],
      termLengths: [
        { value: null, text: "Select a Term Length" },
        { value: "6", text: "6 Months" },
        { value: "9", text: "9 Months" },
        { value: "12", text: "12 Months" },
      ],
      commissions: [
        { value: null, text: "Select a Commission" },
        { value: "1.5", text: "1.5%" },
        { value: "1.75", text: "1.75%" },
        { value: "2", text: "2%" },
        { value: "2.25", text: "2.25%" },
        { value: "2.5", text: "2.5%" },
        { value: "2.75", text: "2.75%" },
        { value: "3", text: "3%" },
      ],
      fileLabel: "Click to Select Files",
      loader: false,
      stripe: null,
      card: null,
      listingCost: 0,
      cardProcessing: false,
    };
  },
  mounted() {
    this.propertyStore.getAllStates().then((res) => {
      if (res.status === 200) {
        this.states = [
          { value: 0, text: "Please select a state" },
          ...res.data.map((state) => ({
            value: state.id,
            text: state.name,
          })),
        ];
        this.formData.state = 0;
      }
    });

    // this.setupStripe();
    this.fetchListingCost();
  },
  // watch: {
  //   currentStep(newStep) {
  //     if (newStep === 6) {
  //       this.setupStripe();
  //     }
  //   },
  // },
  methods: {
    // async setupStripe() {
    //   await this.$nextTick();

    //   // Initialize Stripe
    //   this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
    //   const elements = this.stripe.elements();
    //   this.card = elements.create("card");
    //   this.card.mount(this.$refs.cardElementContainer);
    // },
    async fetchListingCost() {
      this.listingCost = await this.paymentStore.getPropertyListingAmount();
    },
    validateStep(step) {
      if (this.isStepValid(step)) {
        this.nextStep();
      }
    },
    isStepValid(step) {
      switch (step) {
        case 1:
          return (
            this.formData.address1 &&
            this.formData.city &&
            this.formData.state &&
            this.formData.state != 0 &&
            this.formData.zipCode
          );
        case 2:
          return (
            this.formData.bottomLinePrice &&
            this.formData.lengthOfTerm &&
            this.formData.commissionOffered
          );
        case 3:
          return (
            this.formData.openHouseStart && this.formData.openHouseStartTime
          );
        case 4:
          return this.formData.propertyPhotos.length > 0;
        case 5:
          return this.formData.additionalDocuments.length > 0;
        default:
          return false;
      }
    },
    nextStep() {
      this.currentStep++;
    },
    previousStep() {
      this.currentStep--;
    },
    handleFileUpload(event) {
      const files = Array.from(event.target.files);
      if (files.length > 0) {
        if (this.currentStep === 4) {
          this.formData.propertyPhotos.push(...files);
        } else if (this.currentStep === 5) {
          this.formData.additionalDocuments.push(...files);
        }
      }
    },
    removeFile(index, type) {
      if (type === "propertyPhotos") {
        this.formData.propertyPhotos.splice(index, 1);
      } else if (type === "additionalDocuments") {
        this.formData.additionalDocuments.splice(index, 1);
      }
    },
    combineDateTime() {
      if (this.formData.openHouseStart && this.formData.openHouseStartTime) {
        const localDateTimeString = `${this.formData.openHouseStart}T${this.formData.openHouseStartTime}:00`;

        return new Date(localDateTimeString);
      }
      return null;
    },
    getNextDayAtNoon(date) {
      const nextDay = new Date(date);
      nextDay.setDate(nextDay.getDate() + 1);
      nextDay.setHours(12, 0, 0, 0);
      return nextDay;
    },
    async submitForm() {
      document.getElementById("confirmationModalBtn").click();
    },
    async payProperty(paymentData) {
      const data = {
        encryptedPaymentData: paymentData,
      };

      this.paymentStore
        .payPropertyListing(data)
        .then((paymentConfirmation) => {
          if (paymentConfirmation.success) {
            this.addPropertyListing();
            this.cardProcessing = false;
          } else {
            console.log("payProperty", "paymnet failed");
            this.cardProcessing = false;
          }
        })
        .catch((error) => {
          console.error("payProperty", error);
          this.cardProcessing = false;
        });
    },
    addPropertyListing() {
      this.showSuccess = true;
      const formData = new FormData();

      formData.append("address1", this.formData.address1);
      formData.append("address2", this.formData.address2);
      formData.append("city", this.formData.city);
      formData.append("stateId", this.formData.state);
      formData.append("zipCode", this.formData.zipCode);
      formData.append(
        "bottomLinePrice",
        parseFloat(this.formData.bottomLinePrice)
      );
      formData.append(
        "commissionPercentage",
        parseFloat(this.formData.commissionOffered)
      );
      formData.append("termLength", this.formData.lengthOfTerm);

      const openHouseStartDateTime = this.combineDateTime();
      const openHouseStartDateTimeUtc = DateToUTC(
        this.states,
        this.formData.state,
        openHouseStartDateTime
      ).toISOString();
      formData.append("openHouseDate", openHouseStartDateTimeUtc);

      const nextDayAtNoon = this.getNextDayAtNoon(openHouseStartDateTime);
      const nextDayAtNoonUTC = DateToUTC(
        this.states,
        this.formData.state,
        nextDayAtNoon
      ).toISOString();
      formData.append("NextDayAtNoon", nextDayAtNoonUTC);

      this.formData.propertyPhotos.forEach((file) => {
        formData.append(`propertyPhotos`, file);
      });

      this.formData.additionalDocuments.forEach((file) => {
        formData.append(`additionalDocuments`, file);
      });
      this.propertyStore.addProperty(formData).then(() => {
        setTimeout(() => {
          this.$router.push({ name: "myListings" });
        }, 5000);
      });
    },
    _formatCurrency() {
      const numericValue = this.formattedValue.replace(/[^0-9.]/g, "");
      this.formData.bottomLinePrice = numericValue;
      this.formattedValue = this.FormatCurrency(numericValue);
    },
    getPayButtonText() {
      if (this.loader) return "Processing...";

      const cost = FormatCurrency(this.listingCost.toString());
      return `Pay ${cost}`;
    },
    FormatCurrency,
  },
};
</script>

<style scoped>
.step-form {
  margin-top: 20px;
}

.custom-card {
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 80px rgba(0, 0, 0, 0.1);
  border: none;
  margin-bottom: 3%;
  padding: 20px;
  background-color: white;
}

.step-indicator {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.step-indicator li {
  flex: 1;
  text-align: center;
  padding: 10px;
  border-bottom: 2px;
  position: relative;
  font-weight: bold;
  min-width: 100px;
}

.step-indicator li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #ccc;
  z-index: -1;
}

.step-indicator li.active {
  color: #4e73df;
  border-color: #4e73df;
}

.step-indicator li.active::before {
  background: #4e73df;
}

.step-indicator li:first-child::before {
  display: none;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 3.5rem;
  margin-bottom: 1rem;
}

.custom-file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-area {
  border: 2px dashed #ced4da;
  border-radius: 0.25rem;
  padding: 1rem;
  text-align: center;
  transition: border-color 0.15s ease-in-out;
}

.drop-area:hover {
  border-color: #4e73df;
}

.file-list {
  margin-top: 10px;
}

.file-list ul {
  list-style: none;
  padding: 0;
}

.file-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.file-list li button {
  margin-left: 10px;
}

.intro-text {
  margin-bottom: 20px;
  text-align: center;
}

.intro-text h2 {
  color: #4e73df;
  font-size: 24px;
}

.intro-text h3 {
  font-size: 20px;
}

.btn-next,
.btn-prev {
  margin-right: 25px;
}

@media (max-width: 768px) {
  .step-indicator {
    flex-direction: column;
  }
}
</style>
