import { createWebHistory, createRouter } from "vue-router";

import BusinessPage from "../components/Pages/BusinessPage";
import AppPage from "../components/Pages/AppPage";
import InsurancePage from "../components/Pages/InsurancePage";
import PropertyPage from "../components/Pages/PropertyPage";
import BigDataPage from "../components/Pages/BigDataPage";
import DistanceLearningPage from "../components/Pages/DistanceLearningPage";
import ChatBotPage from "../components/Pages/ChatBotPage";
import SoftwarePage from "../components/Pages/SoftwarePage";
import MedicalPage from "../components/Pages/MedicalPage";
import AboutOnePage from "../components/Pages/AboutOnePage";
import AboutTwoPage from "../components/Pages/AboutTwoPage";
import TeamPage from "../components/Pages/TeamPage";
import TestimonialsPage from "../components/Pages/TestimonialsPage";
import CoursesPage from "../components/Pages/CoursesPage";
import CoursesDetailsPage from "../components/Pages/CoursesDetailsPage";
import PricingPage from "../components/Pages/PricingPage";
import FeaturesPage from "../components/Pages/FeaturesPage";
import ServicesPage from "../components/Pages/ServicesPage";
import ServicesTwoPage from "../components/Pages/ServicesTwoPage";
import ServicesThreePage from "../components/Pages/ServicesThreePage";
import ServicesFourPage from "../components/Pages/ServicesFourPage";
import ServicesDetailsPage from "../components/Pages/ServicesDetailsPage";
import AuthenticationPage from "../components/Pages/AuthenticationPage";
import ProfilePage from "../components/Pages/ProfilePage";
import RegisterPage from "../components/Pages/RegisterPage";
import LoginPage from "../components/Pages/LoginPage";
import ForgotPasswordPage from "../components/Pages/ForgotPasswordPage";
import FaqPage from "../components/Pages/FaqPage";
import FaqAgentPage from "../components/Pages/FaqAgentPage";
import FaqHomeOwnerPage from "../components/Pages/FaqHomeOwnerPage";
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
import TermsConditionsPage from "../components/Pages/TermsConditionsPage";
import ComingSoonPage from "../components/Pages/ComingSoonPage";
import ErrorPage from "../components/Pages/ErrorPage";
import ThankYouPage from "../components/Pages/ThankYouPage";
import PortfolioPage from "../components/Pages/PortfolioPage";
import PortfolioTwoPage from "../components/Pages/PortfolioTwoPage";
import PortfolioDetailsPage from "../components/Pages/PortfolioDetailsPage";
import ProductsPage from "../components/Pages/ProductsPage";
import CartPage from "../components/Pages/CartPage";
import CheckoutPage from "../components/Pages/CheckoutPage";
import ProductsDetailsPage from "../components/Pages/ProductsDetailsPage";
import BlogGridPage from "../components/Pages/BlogGridPage";
import BlogLeftSidebarPage from "../components/Pages/BlogLeftSidebarPage";
import BlogRightSidebarPage from "../components/Pages/BlogRightSidebarPage";
import BlogSpecialPage from "../components/Pages/BlogSpecialPage";
import BlogDetailsPage from "../components/Pages/BlogDetailsPage";
import BlogDetailsTwoPage from "../components/Pages/BlogDetailsTwoPage";
import BlogDetailsThreePage from "../components/Pages/BlogDetailsThreePage";
import BlogAuthorPage from "../components/Pages/BlogAuthorPage";
import BlogCategoryPage from "../components/Pages/BlogCategoryPage";
import BlogTagPage from "../components/Pages/BlogTagPage";
import BlogSearchPage from "../components/Pages/BlogSearchPage";
import ContactPage from "../components/Pages/ContactPage";
import ChartPage from "../components/Pages/ChartPage";
import AddPropertyPage from "../components/Pages/AddPropertyPage";
import HomeOwnerDashoard from "../components/Pages/HomeOwnerDashboard.vue";
import AgentDashboard from "../components/Pages/AgentDashboard.vue";
import OnBoarding from "../components/Pages/OnBoarding";
import AddPropertyListing from "../components/Pages/AddPropertyListing";
import HomeListingPage from "../components/Pages/HomeListingPage.vue";
import MyListingsPage from "../components/Pages/MyListingsPage.vue";
import AllListingsPage from "../components/Pages/AllListingsPage.vue";
import BlankPage from "../components/Pages/BlankPage.vue";
import InviteAgentsPage from "../components/Pages/InviteAgentsPage.vue";
import RoleSelectionPage from "../components/Layouts/RoleSelectionPage.vue";
import MembershipPage from "../components/Pages/MembershipPage.vue";
import AgentWinningPage from "../components/Pages/AgentWinningPage.vue";
import HomePage from "../components/Pages/HomePage.vue";
import RegisterPropertyPage from "../components/Pages/RegisterPropertyPage.vue";
import PropertyDetailsPage from "../components/Pages/PropertyDetailsPage.vue";
import CardPayment from "../components/Payment/CardPayment.vue";

// Import for stores
import { useUserStore } from "../stores/users";

const routes = [
  // { path: "/", name: "Medical", component: MedicalPage },
  { path: "/", name: "home", component: HomePage },
  { path: "/business", name: "BusinessPage", component: BusinessPage },
  { path: "/app", name: "AppPage", component: AppPage },
  { path: "/insurance", name: "InsurancePage", component: InsurancePage },
  { path: "/property", name: "PropertyPage", component: PropertyPage },
  { path: "/big-data", name: "BigDataPage", component: BigDataPage },
  {
    path: "/distance-learning",
    name: "DistanceLearningPage",
    component: DistanceLearningPage,
  },
  { path: "/chat-bot", name: "ChatBotPage", component: ChatBotPage },
  { path: "/software", name: "SoftwarePage", component: SoftwarePage },
  { path: "/medical", name: "MedicalPage", component: MedicalPage },
  { path: "/about-us", name: "AboutOnePage", component: AboutOnePage },
  { path: "/about-us-two", name: "AboutTwoPage", component: AboutTwoPage },
  { path: "/team", name: "TeamPage", component: TeamPage },
  {
    path: "/testimonials",
    name: "TestimonialsPage",
    component: TestimonialsPage,
  },
  { path: "/courses", name: "CoursesPage", component: CoursesPage },
  {
    path: "/courses-details",
    name: "CoursesDetailsPage",
    component: CoursesDetailsPage,
  },
  { path: "/pricing", name: "PricingPage", component: PricingPage },
  { path: "/features", name: "FeaturesPage", component: FeaturesPage },
  { path: "/services", name: "ServicesPage", component: ServicesPage },
  {
    path: "/services-two",
    name: "ServicesTwoPage",
    component: ServicesTwoPage,
  },
  {
    path: "/services-three",
    name: "ServicesThreePage",
    component: ServicesThreePage,
  },
  {
    path: "/services-four",
    name: "ServicesFourPage",
    component: ServicesFourPage,
  },
  {
    path: "/services-details",
    name: "ServicesDetailsPage",
    component: ServicesDetailsPage,
  },
  {
    path: "/profile-authentication",
    name: "AuthenticationPage",
    component: AuthenticationPage,
  },
  {
    path: "/profile",
    name: "ProfilePage",
    component: ProfilePage,
  },
  {
    path: "/register",
    name: "RegisterPage",
    component: RegisterPage,
  },
  {
    path: "/cardpayment-test",
    name: "cardpayment-test",
    component: CardPayment,
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/under-development",
    name: "UnderDevelopment",
    component: BlankPage,
  },
  {
    path: "/charts",
    name: "ChartPage",
    component: ChartPage,
  },
  {
    path: "/add-property",
    name: "AddPropertyPage",
    component: AddPropertyPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/register-property",
    name: "RegisterPropertyPage",
    component: RegisterPropertyPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/lost-password",
    name: "ForgotPasswordPage",
    component: ForgotPasswordPage,
  },
  { path: "/faq", name: "FaqPage", component: FaqPage },
  {
    path: "/homeowner-faq",
    name: "HomeOwnerFaqPage",
    component: FaqHomeOwnerPage,
  },
  { path: "/agent-faq", name: "AgentFaqPage", component: FaqAgentPage },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  {
    path: "/terms-conditions",
    name: "TermsConditionsPage",
    component: TermsConditionsPage,
  },
  { path: "/coming-soon", name: "ComingSoonPage", component: ComingSoonPage },
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
  { path: "/thank-you", name: "ThankYouPage", component: ThankYouPage },
  { path: "/portfolio", name: "PortfolioPage", component: PortfolioPage },
  {
    path: "/portfolio-two",
    name: "PortfolioTwoPage",
    component: PortfolioTwoPage,
  },
  {
    path: "/portfolio-details",
    name: "PortfolioDetailsPage",
    component: PortfolioDetailsPage,
  },
  { path: "/products", name: "ProductsPage", component: ProductsPage },
  { path: "/cart", name: "CartPage", component: CartPage },
  { path: "/checkout", name: "CheckoutPage", component: CheckoutPage },
  {
    path: "/products-details",
    name: "ProductsDetailsPage",
    component: ProductsDetailsPage,
  },
  { path: "/blog-grid", name: "BlogGridPage", component: BlogGridPage },
  {
    path: "/blog-left-sidebar",
    name: "BlogLeftSidebarPage",
    component: BlogLeftSidebarPage,
  },
  {
    path: "/blog-right-sidebar",
    name: "BlogRightSidebarPage",
    component: BlogRightSidebarPage,
  },
  {
    path: "/blog-special",
    name: "BlogSpecialPage",
    component: BlogSpecialPage,
  },
  {
    path: "/blog-details",
    name: "BlogDetailsPage",
    component: BlogDetailsPage,
  },
  {
    path: "/blog-details-two",
    name: "BlogDetailsTwoPage",
    component: BlogDetailsTwoPage,
  },
  {
    path: "/blog-details/:id",
    name: "BlogDetailsPage",
    component: BlogDetailsThreePage,
  },

  { path: "/blog-author", name: "BlogAuthorPage", component: BlogAuthorPage },
  {
    path: "/blog-categories",
    name: "BlogCategoryPage",
    component: BlogCategoryPage,
  },
  { path: "/blog-tag", name: "BlogTagPage", component: BlogTagPage },
  {
    path: "/blog-search-result",
    name: "BlogSearchPage",
    component: BlogSearchPage,
  },
  { path: "/contact", name: "ContactPage", component: ContactPage },
  {
    path: "/HomeOwnerDashboard",
    name: "HomeOwnerDashboard",
    component: HomeOwnerDashoard,
  },
  {
    path: "/AgentDashboard",
    name: "AgentDashboard",
    component: AgentDashboard,
  },
  {
    path: "/OnBoarding",
    name: "OnBoarding",
    component: OnBoarding,
  },
  {
    path: "/AddPropertyListing",
    name: "AddPropertyListing",
    component: AddPropertyListing,
  },
  {
    path: "/choose-your-role",
    name: "roleSelection",
    component: RoleSelectionPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/my-listings",
    name: "myListings",
    component: MyListingsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/all-listings",
    name: "allListings",
    component: AllListingsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/home-listing/:id",
    name: "homeListing",
    component: HomeListingPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/property-details/:id",
    name: "PropertyDetails",
    component: PropertyDetailsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/invite-agents/:propertyId",
    name: "inviteAgents",
    component: InviteAgentsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/manage-membership",
    name: "manageMembership",
    component: MembershipPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/winning-bid/:auctionId",
    name: "winningBid",
    component: AgentWinningPage,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  await userStore.authenticate();
  const isAuthenticated = await userStore.isAuthenticated;

  const requiresAuth = to.meta.requiresAuth;

  if (isAuthenticated) {
    await userStore.getUserInfo();
    const userDetails = await userStore.getUser;

    if (to.path === "/login") {
      if (userDetails != null && userDetails.roleId == 4) {
        return next({
          path: "/choose-your-role",
          query: { redirect: to.fullPath },
        });
      } else if (userDetails != null && userDetails.roleId == 3) {
        return next("/HomeOwnerDashboard");
      } else if (userDetails != null && userDetails.roleId == 2) {
        return next("/AgentDashboard");
      } else {
        return next("/");
      }
    }

    if (
      to.path === "/choose-your-role" &&
      userDetails != null &&
      userDetails.roleId != 4
    ) {
      if (userDetails != null && userDetails.roleId == 3) {
        return next("/HomeOwnerDashboard");
      } else if (userDetails != null && userDetails.roleId == 2) {
        return next("/AgentDashboard");
      } else {
        return next("/");
      }
    }

    if (
      userDetails != null &&
      userDetails.roleId == 4 &&
      to.path != "/choose-your-role"
    ) {
      return next({
        path: "/choose-your-role",
        query: { redirect: to.fullPath },
      });
    }

    if (
      userDetails != null &&
      userDetails.roleId == 2 &&
      !userDetails.onboardingCompleted &&
      to.path != "/manage-membership"
    ) {
      return next({
        path: "/manage-membership",
        query: { redirect: to.fullPath },
      });
    }

    const registerPathPattern = /^\/register\/\d+$/;
    if (registerPathPattern.test(to.path)) {
      if (userDetails != null && userDetails.roleId == 3) {
        return next("/HomeOwnerDashboard");
      } else if (userDetails != null && userDetails.roleId == 2) {
        return next("/AgentDashboard");
      } else {
        return next("/");
      }
    }
  }

  if (requiresAuth && !isAuthenticated) {
    return next({ path: "/login", query: { redirect: to.fullPath } });
  }

  next();
});

export default router;
