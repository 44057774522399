<template>
  <div>
    <NavbarStyleNine />
    <PageTitle
      v-if="auction"
      subTitle="Auction Winner"
      :heding="`Congratulations ${auction.winner.user.firstName} ${
        auction.winner.user.lastName
      }, you won the auction at ${getPropertyName()}`"
    />
    <div class="pricing-area bg-f1f5fd pb-75">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-center">
              <button
                @click="onPayClicked()"
                class="btn btn-style-two btn-lg block"
              >
                {{ getPayButtonText() }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      id="confirmationModalBtn"
      type="button"
      class="btn btn-primary d-none"
      data-bs-toggle="modal"
      data-bs-target="#cardPaymentModel"
    ></button>
    <button
      id="confirmationModalCloseBtn"
      type="button"
      class="btn btn-primary d-none"
      data-bs-dismiss="modal"
      data-bs-target="#cardPaymentModel"
    ></button>
    <FooterStyleFive />
    <CardPayment
      @pay="payBid"
      :loading="cardProcessing"
      :btnText="auction ? auction.bid.bidAmount : ''"
    ></CardPayment>
  </div>
</template>

<script>
import { useAgentStore } from "../../stores/agent";
import { usePaymentStore } from "../../stores/payments";
import { useAuctionStore } from "../../stores/auctions";
import { usePropertyStore } from "../../stores/properties";
import { useBidStore } from "../../stores/bids";
import { useRoute, useRouter } from "vue-router";
// import { loadStripe } from "@stripe/stripe-js";
import NavbarStyleNine from "../Layouts/NavbarStyleNine";
import PageTitle from "../Common/PageTitle";
import FooterStyleFive from "../Layouts/FooterStyleFive";
import FormatCurrency from "../../utils/Currency";
import CardPayment from "../Payment/CardPayment.vue";

export default {
  name: "AgentWinningPage",
  components: {
    NavbarStyleNine,
    PageTitle,
    FooterStyleFive,
    CardPayment,
  },
  setup() {
    const route = useRoute();
    const agentStore = useAgentStore();
    const auctionStore = useAuctionStore();
    const router = useRouter();
    const paymentStore = usePaymentStore();
    const propertyStore = usePropertyStore();
    const bidStore = useBidStore();

    return {
      route,
      agentStore,
      auctionStore,
      router,
      paymentStore,
      propertyStore,
      bidStore,
    };
  },
  data() {
    return {
      auction: null,
      agent: null,
      loader: false,
      stripe: null,
      card: null,
      states: [],
      amount: "0",
      cardProcessing: false,
    };
  },
  methods: {
    FormatCurrency,
    findStateById(stateId) {
      const state = this.states.find((state) => state.id === stateId);
      if (state) {
        return state.name;
      } else {
        return "";
      }
    },
    async fetchAgent() {
      const agent = await this.agentStore.fetchAgent();
      if (agent) {
        this.agent = agent;
      }
    },
    async payBid(paymentData) {
      const data = {
        payment: paymentData,
        amount: this.auction.bid.bidAmount,
      };
      this.paymentStore
        .payWinningBid(data)
        .then((paymentConfirmation) => {
          if (paymentConfirmation.success) {
            // Update is paid flag for bid
            this.bidStore
              .payBid(this.auction.bid.bidId)
              .then(() => {
                this.router.push("/AgentDashboard");
                document.getElementById("confirmationModalCloseBtn").click();
                this.cardProcessing = false;
              })
              .catch((error) => {
                console.error(error);
                document.getElementById("confirmationModalCloseBtn").click();
                this.cardProcessing = false;
              });
          } else {
            console.log("payBid", "paymnet failed");
            this.cardProcessing = false;
          }
        })
        .catch((error) => {
          console.error("Payment failed", error);
          this.cardProcessing = false;
        });
    },
    fetchStates() {
      this.propertyStore.getAllStates().then((res) => {
        if (res.status == 200) {
          this.states = res.data;
        }
      });
    },
    async fetchAuction() {
      this.auction = await this.auctionStore.getWinningAuction(
        this.route.params.auctionId
      );
      this.amount = this.FormatCurrency(this.auction.bid.bidAmount.toString());
    },
    async onPayClicked() {
      document.getElementById("confirmationModalBtn").click();
    },
    getPayButtonText() {
      if (this.loader) return "Processing...";
      if (this.agent == null) return "Pay";
      if (this.auction == null) return "Pay";

      return `Pay ${this.FormatCurrency(
        this.auction.bid.bidAmount.toString()
      )}`;
    },
    getPropertyName() {
      return (
        this.auction.auction.property.address1.trimEnd() +
        ", " +
        this.auction.auction.property.city.trimEnd() +
        ", " +
        this.findStateById(this.auction.auction.property.stateId) +
        " " +
        this.auction.auction.property.zipCode.trimEnd()
      );
    },
  },
  async beforeMount() {
    await this.fetchAgent();
    await this.fetchAuction();
    await this.fetchStates();
  },
};
</script>

<style scoped>
.single-pricing-box {
  border: 1px solid #0d6efd;
}

.features-list li:hover i {
  background-color: black !important;
}
</style>
