<template>
  <div class="services-area with-top-border pt-75 pb-75">
    <div class="container">
      <div class="section-title">
        <h2 class="nunito-font">Agent Dashboard</h2>
      </div>
      <div v-if="listings">text goes here</div>
      <div class="row">
        <div class="courses-details-area ptb-50">
          <div class="container">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-8 col-md-12">
                <div class="courses-details-desc">
                  <div
                    style="padding-top: 0"
                    class="tab-content"
                    id="myTabContent"
                  >
                    <div
                      class="tab-pane fade show active"
                      id="overview"
                      role="tabpanel"
                    >
                      <div class="courses-curriculum">
                        <h3>Invited Listings</h3>
                        <ul v-if="invites.length > 0">
                          <li :key="invite.id" v-for="invite in invites">
                            <router-link
                              :to="'/property-details/' + invite.property.id"
                              class="d-flex justify-content-between align-items-center w-100"
                            >
                              <span class="courses-name">
                                {{
                                  invite.property.address1.trimEnd() +
                                  ", " +
                                  invite.property.city.trimEnd() +
                                  ", " +
                                  findStateById(invite.property.stateId) +
                                  " " +
                                  invite.property.zipCode.trimEnd()
                                }}
                              </span>
                              <div class="courses-meta">
                                <span class="duration">{{
                                  calculateTimeLeft(
                                    invite.property.openHouseDate
                                  )
                                }}</span>
                              </div>
                            </router-link>
                          </li>
                        </ul>
                        <ul v-else>
                          <li>
                            <div
                              class="d-flex justify-content-between align-items-center"
                            >
                              <span class="courses-name"
                                >No invited listings found
                              </span>
                            </div>
                          </li>
                        </ul>
                        <h3>Upcoming Open Houses</h3>
                        <ul v-if="openHouses.length > 0">
                          <li
                            :key="openHouse.id"
                            v-for="openHouse in openHouses"
                          >
                            <router-link
                              :to="'/property-details/' + openHouse.id"
                              class="d-flex justify-content-between align-items-center"
                            >
                              <span class="courses-name">
                                {{
                                  openHouse.address1.trimEnd() +
                                  ", " +
                                  openHouse.city.trimEnd() +
                                  ", " +
                                  findStateById(openHouse.stateId) +
                                  " " +
                                  openHouse.zipCode.trimEnd()
                                }}
                              </span>
                              <div class="courses-meta">
                                <span class="duration">{{
                                  calculateTimeLeft(openHouse.openHouseDate)
                                }}</span>
                                <span class="status locked"
                                  ><i class="bx bx-lock-alt"></i
                                ></span>
                              </div>
                            </router-link>
                          </li>
                        </ul>
                        <ul v-else>
                          <li>
                            <div
                              class="d-flex justify-content-between align-items-center"
                            >
                              <span class="courses-name"
                                >No open houses found</span
                              >
                            </div>
                          </li>
                        </ul>
                        <h3>Auctions</h3>
                        <ul v-if="auctions.length > 0">
                          <li
                            :key="auction.propertyId"
                            v-for="auction in auctions"
                          >
                            <router-link
                              :to="'/property-details/' + auction.propertyId"
                              class="d-flex justify-content-between align-items-center"
                            >
                              <span class="courses-name">
                                {{
                                  auction.property.address1.trimEnd() +
                                  ", " +
                                  auction.property.city.trimEnd() +
                                  ", " +
                                  findStateById(auction.property.stateId) +
                                  " " +
                                  auction.property.zipCode.trimEnd()
                                }}
                              </span>
                              <div class="courses-meta">
                                <span class="questions">{{
                                  auctionStore.auctionStatus[auction.status]
                                }}</span>
                                <span class="duration">{{
                                  calculateTimeLeft(auction.startTime)
                                }}</span>
                                <span class="status locked"
                                  ><i class="bx bx-lock-alt"></i
                                ></span>
                              </div>
                            </router-link>
                          </li>
                        </ul>
                        <ul v-else>
                          <li>
                            <div
                              class="d-flex justify-content-between align-items-center"
                            >
                              <span class="courses-name"
                                >No open houses found</span
                              >
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    id="confirmationModalBtn"
    type="button"
    class="btn btn-primary d-none"
    data-bs-toggle="modal"
    data-bs-target="#confirmationModal"
  ></button>
  <button
    id="confirmationModalCloseBtn"
    type="button"
    class="btn btn-primary d-none"
    data-bs-dismiss="modal"
    data-bs-target="#confirmationModal"
  ></button>
  <div
    class="modal fade"
    id="confirmationModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="confirmationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="confirmationModalLabel">
            Payment Confirmation
          </h4>
        </div>
        <form @submit.prevent="handleSubmit">
          <div
            v-if="selectedInvite && selectedInvite.property"
            class="px-3 pt-3"
          >
            {{
              selectedInvite.property.address1.trimEnd() +
              ", " +
              selectedInvite.property.city.trimEnd() +
              ", " +
              findStateById(selectedInvite.property.stateId) +
              " " +
              selectedInvite.property.zipCode.trimEnd()
            }}
          </div>
          <div class="modal-body fs-5">
            <div>
              <div
                ref="cardElementContainer"
                class="card-element mt-4 mb-4"
              ></div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary fs-5"
              data-bs-dismiss="modal"
              :disabled="loader"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary fs-5"
              :disabled="loader"
              @click="payInvite"
            >
              {{ getPayButtonText() }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuctionInviteStore } from "../../stores/auctionInvite";
import { useAuctionStore } from "../../stores/auctions";
import { useAgentStore } from "../../stores/agent";
import { useDashboardStore } from "../../stores/dashboard";
import { usePropertyStore } from "../../stores/properties";
import { usePaymentStore } from "../../stores/payments";
import FormatCurrency from "../../utils/Currency";
import { loadStripe } from "@stripe/stripe-js";

export default {
  setup() {
    const auctionInviteStore = useAuctionInviteStore();
    const auctionStore = useAuctionStore();
    const dashboardStore = useDashboardStore();
    const agentStore = useAgentStore();
    const propertyStore = usePropertyStore();
    const paymentStore = usePaymentStore();

    return {
      auctionInviteStore,
      auctionStore,
      dashboardStore,
      agentStore,
      propertyStore,
      paymentStore,
    };
  },
  data() {
    return {
      listings: false,
      updateProfile: false,
      invites: [],
      openHouses: [],
      auctions: [],
      states: [],
      agent: null,
      loader: false,
      stripe: null,
      card: null,
      selectedInvite: null,
    };
  },
  methods: {
    FormatCurrency,
    async setupStripe() {
      await this.$nextTick();

      // Initialize Stripe
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      const elements = this.stripe.elements();
      this.card = elements.create("card");
      this.card.mount(this.$refs.cardElementContainer);
    },
    calculateTimeLeft(dateString) {
      const utcDate = new Date(dateString);
      const offset = utcDate.getTimezoneOffset();
      const localDate = new Date(utcDate.getTime() - offset * 60 * 1000);

      const currentDate = new Date();
      const timeDiff = localDate - currentDate;
      const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      if (daysDiff > 0) {
        return `${daysDiff} day${daysDiff === 1 ? "" : "s"} left`;
      } else if (hoursDiff > 0) {
        return `${hoursDiff} hour${hoursDiff === 1 ? "" : "s"} left`;
      } else {
        return "";
      }
    },
    findStateById(stateId) {
      const state = this.states.find((state) => state.id === stateId);
      if (state) {
        return state.name;
      } else {
        return "";
      }
    },
    fetchAllInvitedListings() {
      this.dashboardStore.agentInvites().then((res) => {
        if (res.status == 200) {
          this.invites = res.data;
        }
      });
    },
    fetchAllUpcomingOpenHouses() {
      this.dashboardStore.agentOpenHouses().then((res) => {
        if (res.status == 200) {
          this.openHouses = res.data;
        }
      });
    },
    fetchAllAuctions() {
      this.dashboardStore.agentAuctions().then((res) => {
        if (res.status == 200) {
          this.auctions = res.data;
        }
      });
    },
    fetchAgent() {
      this.agentStore.fetchAgent().then((res) => {
        if (res.status == 200) {
          this.agent = this.agentStore.agent;
        }
      });
    },
    fetchStates() {
      this.propertyStore.getAllStates().then((res) => {
        if (res.status == 200) {
          this.states = res.data;
        }
      });
    },
    updateStatus(invite, accepted) {
      // First check if the agent ahs paid for the invite
      if (accepted) {
        if (this.agentStore.getAgent.membership.tier < 2) {
          this.selectedInvite = invite;
          document.getElementById("confirmationModalBtn").click();
        }
      }
    },
    async payInvite() {
      console.log(this.selectedInvite);
      if (this.agentStore.getAgent == null) {
        console.error("Agent is null");
        console.log(this.agentStore.getAgent);
        return;
      }

      this.loader = true;

      // Get the token or payment method from Stripe
      const { error, paymentMethod } = await this.stripe.createPaymentMethod({
        type: "card",
        card: this.card,
      });

      if (error) {
        console.error(error);
        this.loader = false;
        return;
      }

      console.log("================================================");
      console.log(paymentMethod);
      //paymentMethod.id, membershipTier

      const data = {
        paymentMethodId: paymentMethod.id,
        membershipTier: this.agentStore.getAgent.membership.tier,
      };
      this.paymentStore
        .payInvite(data)
        .then((paymentConfirmation) => {
          if (paymentConfirmation.requiresAction) {
            // Handle 3D Secure or authentication
            this.stripe
              .confirmCardPayment(paymentConfirmation.clientSecret)
              .then((result) => {
                if (result.error) {
                  console.error(result.error.message);
                  this.loader = false;
                } else {
                  this.auctionInviteStore
                    .updateStatus(this.selectedInvite.auctionAgentInvite, true)
                    .then((res) => {
                      if (res.status == 200) {
                        console.log(res);
                        this.fetchAllInvitedListings();
                        this.fetchAllUpcomingOpenHouses();
                        this.fetchAllAuctions();
                        this.fetchAgent();
                        document
                          .getElementById("confirmationModalCloseBtn")
                          .click();
                        this.loader = false;
                      }
                    });
                }
              });
          } else {
            this.auctionInviteStore
              .updateStatus(this.selectedInvite.auctionAgentInvite, true)
              .then((res) => {
                if (res.status == 200) {
                  console.log(res);
                  this.fetchAllInvitedListings();
                  this.fetchAllUpcomingOpenHouses();
                  this.fetchAllAuctions();
                  this.fetchAgent();
                  document.getElementById("confirmationModalCloseBtn").click();
                  this.loader = false;
                }
              });
          }
        })
        .catch((error) => {
          console.error("Payment failed", error);
          this.loader = false;
        });
    },
    getPayButtonText() {
      if (this.agentStore.getAgent == null) return "Pay";
      if (this.agentStore.getAgent.membership == null) return "Pay";

      return `Pay ${this.FormatCurrency(
        this.agentStore.getAgent.membership.entryFee.toString()
      )}`;
    },
  },
  mounted() {
    this.setupStripe();
    this.fetchStates();
    this.fetchAllInvitedListings();
    this.fetchAllUpcomingOpenHouses();
    this.fetchAllAuctions();
    this.fetchAgent();
  },
};
</script>
